import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import SecureLS from "secure-ls";
import { accessApi, apiPortal, responseApi } from '@/provider'
import { toEncrypt } from "../provider";
const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    inboxFitur: true,
    redeemFitur: false,
    storeFitur: true,
    bannerFitur: true,
    isLoading: true,
    user: null,
    profile: null,
    token: null,
    url: null,
    pid: null,
    mno_id: null,
    listgame: [],
    listgamefox: [],
    detailgame: {},
    tokenLife: null,
    statusStore: false,
    gameId: null,
    gameIdUnix: null,
    coin: 0,
    point: 0,
    subscribe: 0,
    dataPoint: [],
    reward: null,
    mode: 'reg',
    // reward:[
      // {id: 1, name: 'Apple Watch',  url: require("@/assets/reward/star_applewatch.png")},
      // {id: 2, name: 'Galaxy Fit', url: require("@/assets/reward/star_galaxyfit.png")},
      // {id: 3, name: 'Iphone', url: require("@/assets/reward/star_iphone.png")},
      // {id: 4, name: 'Motor',  url: require("@/assets/reward/star_motor.png")},
      // {id: 5, name: 'Playstation',  url: require("@/assets/reward/star_playstation.png")},
      // {id: 6, name: 'Smart TV', url: require("@/assets/reward/star_smarttv.png")}
    // ],
    bannerList: [
      {
        name: 'Pesta Reward',
        image: require('@/assets/pesta-reward.png'),
        url: 'leaderboard',
      },
    ],
    leaderboardList:[
      // {
      //   msisdn: "62812345xxxx",
      //   point: "10000"
      // },
      // {
      //   msisdn: "62812111xxxx",
      //   point: "9000"
      // },
      // {
      //   msisdn: "62822443xxxx",
      //   point: "8000"
      // },
      // {
      //   msisdn: "62812645xxxx",
      //   point: "7000"
      // },
      // {
      //   msisdn: "62822349xxxx",
      //   point: "6000"
      // },
      // {
      //   msisdn: "62822340xxxx",
      //   point: "5000"
      // },
      // {
      //   msisdn: "62811340xxxx",
      //   point: "4500"
      // },
      // {
      //   msisdn: "62812040xxxx",
      //   point: "3000"
      // },
      // {
      //   msisdn: "62822390xxxx",
      //   point: "2500"
      // },
      // {
      //   msisdn: "62811140xxxx",
      //   point: "1000"
      // },
    ],
    popupReward: [
      {
        point: "25",
        image: require("@/assets/hadiah-1gb.png"),
        title: "Selamat!",
        description:
          "Kamu dapat hadiah langsung paket Data 1GB! Ambil hadiahnya sekarang!"
      },
      {
        point: "26",
        image: require("@/assets/hadiah-100rb.png"),
        title: "Selamat!",
        description:
          "Kamu dapat hadiah langsung Uang Tunai 100 ribu! Ambil hadiahnya sekarang!"
      },
      {
        point: "27",
        image: require("@/assets/hadiah-500rb.png"),
        title: "Selamat!",
        description:
          "Kamu dapat hadiah langsung Uang Tunai 500 ribu! Ambil hadiahnya sekarang!"
      },
      {
        point: "28",
        image: require("@/assets/hadiah-1jt.png"),
        title: "Selamat!",
        description:
          "Kamu dapat hadiah langsung Uang Tunai 1 juta! Ambil hadiahnya sekarang!"
      },
      {
        point: "29",
        image: require("@/assets/hadiah-1.5jt.png"),
        title: "Selamat!",
        description:
          "Kamu dapat hadiah langsung Uang Tunai 1,5 juta! Ambil hadiahnya sekarang!"
      },
      {
        point: "30",
        image: require("@/assets/hadiah-2jt.png"),
        title: "Selamat!",
        description:
          "Kamu dapat hadiah langsung Uang Tunai 2 juta! Ambil hadiahnya sekarang!"
      }
    ],
    redeemList: [
      {
        id: 1,
        name: "Gratis Pulsa 5 Ribu",
        image: require("@/assets/redeem-5k.png"),
        price: 1000,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis velit arcu et sit lacinia id. Nisl facilisi quisque senectus varius.",
        url: "#"
      },
      {
        id: 2,
        name: "Gratis Pulsa 10 Ribu",
        image: require("@/assets/redeem-10k.png"),
        price: 1000,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis velit arcu et sit lacinia id. Nisl facilisi quisque senectus varius.",
        url: "#"
      },
      {
        id: 3,
        name: "Gratis Pulsa 25 Ribu",
        image: require("@/assets/redeem-25k.png"),
        price: 1000,
        description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Convallis velit arcu et sit lacinia id. Nisl facilisi quisque senectus varius.",
        url: "#"
      }
    ],
    inboxList: [],
    storeListTree: [
      {
        id: 1,
        textToken: "1",
        name: "Tambah 1 Token",
        image: null,
        price: 1000,
        description: "Rp.1000/beli",
        url: "sms:92344?&body=GAME 1000"
      },
      {
        id: 2,
        textToken: "3",
        name: "Tambah 3 Token",
        image: null,
        price: 2000,
        description: "Rp.2000/beli",
        url: "sms:92344?&body=GAME 2000"
      },
      {
        id: 3,
        textToken: "5",
        name: "Tambah 5 Token",
        image: null,
        price: 3000,
        description: "Rp.3000/beli",
        url: "sms:92344?&body=GAME 3000"
      },
      {
        id: 4,
        textToken: "10",
        name: "Tambah 10 Token",
        image: null,
        price: 5000,
        description: "Rp.5000/beli",
        url: "sms:92344?&body=GAME 5000"
      }
    ],
    storeListSmart: [
      {
        id: 1,
        textToken: "1",
        name: "Tambah 1 Token",
        image: null,
        price: 1110,
        description: "Rp.1110/beli",
        url: "http://117.54.3.23:1481/smart?aid=88&adn=99878&keyword=token1"
      },
      {
        id: 2,
        textToken: "3",
        name: "Tambah 3 Token",
        image: null,
        price: 2220,
        description: "Rp.2220/beli",
        url: "http://117.54.3.23:1481/smart?aid=88&adn=99878&keyword=token2"
      },
      {
        id: 3,
        textToken: "5",
        name: "Tambah 5 Token",
        image: null,
        price: 3330,
        description: "Rp.3330/beli",
        url: "http://117.54.3.23:1481/smart?aid=88&adn=99878&keyword=token3"
      },
      {
        id: 4,
        textToken: "10",
        name: "Tambah 10 Token",
        image: null,
        price: 5550,
        description: "Rp.5550/beli",
        url: "http://117.54.3.23:1481/smart?aid=88&adn=99878&keyword=token4"
      }
    ],
    popupToken: {
      point: null,
      image: require("@/assets/token-null.png"),
      title: "Token Tidak Cukup!",
      description:
        "Klik tombol Tambah Token dibawah dan pastikan PULSA CUKUP."
    },
    // appKey: 'eyJhcHBfYWxpYXMiOiJ1dGVuZ2dvIiwiYXBwX25hbWUiOiJVbGFyIFRlbmdnbyIsImNwX2lkIjoxfQ==aGeWlvxnnXLxKA7fMKH1',
    appKey: 'eyJhcHBfYWxpYXMiOiJjdWFudGFzdGljIiwiYXBwX25hbWUiOiJDdWFudGFzdGljIiwiY3BfaWQiOjF957ynlmruf7QmBtJtDXGh',
    secretKey: 'Bismillah',
    telco: null,
    gamesFox : [
      {
        gamefox_name: "Adrenaline BMXs", 
        banner: require("@/assets/gamefox/gamefox1.png"),
        linkApp: "https://apaajadeh.com/games/AdrenalineBMX.apk",
      },
      {
        gamefox_name: "3D World Racing Challenge", 
        banner: require("@/assets/gamefox/gamefox2.png"),
        linkApp: "https://apaajadeh.com/games/3DWorldRacingChallenge.apk",
      },
      {
        gamefox_name: "Alien Parking", 
        banner: require("@/assets/gamefox/gamefox3.png"),
        linkApp: "https://apaajadeh.com/games/AlienParking.apk",
      },
      {
        gamefox_name: "Amazon River Challange", 
        banner: require("@/assets/gamefox/gamefox4.png"),
        linkApp: "https://apaajadeh.com/games/AmazonRiverChallenge.apk",
      },
      {
        gamefox_name: "Anthony The Mad Scientist", 
        banner: require("@/assets/gamefox/gamefox5.png"),
        linkApp: "https://apaajadeh.com/games/AnthonyTheMadScientist.apk",
      },
      {
        gamefox_name: "Addictive Truck Delivery", 
        banner: require("@/assets/gamefox/gamefox6.png"),
        linkApp: "https://apaajadeh.com/games/AddictiveTruckDelivery.apk",
      },
      {
        gamefox_name: "Alien Commando Strike", 
        banner: require("@/assets/gamefox/gamefox7.png"),
        linkApp: "https://apaajadeh.com/games/AlienCommandoStrike.apk",
      },
      {
        gamefox_name: "A Real Mole Adventure", 
        banner: require("@/assets/gamefox/gamefox8.png"),
        linkApp: "https://apaajadeh.com/games/arealmoleadventure.apk",
      }
    ],
  },
  plugins: [
    createPersistedState({
      storage: {
        getItem: key => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: key => ls.remove(key)
      }
    })
  ],
  getters: {
    isUser: state => state.user,
    getUser: state => state.user,
    getSecret: state => state.secretKey,
    getGameId: state => state.gameId,
    getLifeId: state => state.tokenLife,
    getCoin: state => state.coin,
    getMode: state => state.mode,
    getRedeem: state => state.redeemList,
    getStore: state => state.storeList,
    getBanner: state => state.bannerList,
    getPid: state => state.pid,
    getInbox: state => state.inboxList,
    getReward: state => state.popupReward,
    getLeaderboard: state => state.leaderboardList,
    getGameIdUnix: state => state.gameIdUnix,
    getTelco: state => state.telco,
  },
  mutations: {
    SET_TOKEN: (state, value) => value ? (state.token = value) : (state.token = null),
    SET_USER: (state, value) => value ? (state.user = value) : (state.user = null),
    SET_URL: (state, value) => value ? (state.url = value) : (state.url = null),
    SET_LIST_GAME: (state, value) => value ? (state.listgame = value) : (state.listgame = []), 
    SET_LIST_GAMEFOX: (state, value) => value ? (state.listgamefox = value) : (state.listgamefox = []),
    SET_PROFILE: (state, value) => value ? (state.profile = value) : (state.profile = null),
    SET_TOKEN_LIFE: (state, value) => value ? (state.tokenLife = value) : (state.tokenLife = null),
    SET_DATA_POINT: (state, value) => value ? (state.dataPoint = value) : (state.dataPoint = []),
    SET_GAME_ID: (state, value) => value ? (state.gameId = value) : (state.gameId = null),
    SET_DETAIL_GAME: (state, value) => value ? (state.detailgame = value) : (state.detailgame = {}),
    SET_COIN: (state, value) => value ? (state.coin = value) : (state.coin = 0),
    SET_POINT: (state, value) => value ? (state.point = value) : (state.point = 0),
    SET_SUBSCRIBE: (state, value) => value ? (state.subscribe = value) : (state.subscribe = 0),
    SET_REWARD: (state, value) => value ? (state.reward = value) : (state.reward = null),
    SET_MODE: (state, value) => value ? (state.mode = value) : (state.mode = 'free'),
    SET_TELCO: (state, value) => value ? (state.telco = value) : (state.telco = null),
    SET_BANNER_LIST: (state, value) => value ? (state.bannerList = value) : (state.bannerList = []),
    SET_REWARD_LIST: (state, value) => value ? (state.popupReward = value) : (state.popupReward = []),
    SET_STORE_LIST: (state, value) => value ? (state.storeList = value) : (state.storeList = []),
    SET_LEADERBOARD_LIST: (state, value) => value ? (state.leaderboardList = value) : (state.leaderboardList = []),
    SET_INBOX_LIST: (state, value) => value ? (state.inboxList = value) : (state.inboxList = []),
    SET_GAME_ID_UNIX: (state, value) => value ? (state.gameIdUnix = value) : (state.gameIdUnix = null),
    SET_MNO_ID: (state, value) => value ? (state.mno_id = value) : (state.mno_id = null),
  },
  actions: {
    getProfile: ({commit, getters}) => {
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
          const msisdn = toEncrypt(String(getters.getUser), getters.getSecret) 
          const sendData = { req: msisdn }
          const headers = { Authorization : `bearer ${token}`}
          const { data } = await apiPortal.post('gameProfile', sendData, { headers })
          const dataProfile = await JSON.parse(responseApi(data))
          const JsonDataProfile = dataProfile
          commit('SET_TELCO', JsonDataProfile.telco)
          commit('SET_PROFILE', JsonDataProfile)
          resolve(data);
        }).catch(error => {
          reject(error)
        })
      })
    },
    getDetail: ({commit, getters}) => {
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
          const msisdn = toEncrypt(String(getters.getUser), getters.getSecret)
          const sendData = { req: msisdn }
          const headers = { Authorization : `bearer ${token}`}
          const { data } = await apiPortal.post('gameDetail', sendData, { headers })
          const dataDetail = await JSON.parse(responseApi(data))
          const JsonDataDetail = dataDetail
          // commit('SET_COIN', 1)
          commit('SET_COIN', JsonDataDetail.token)
          if (getters.getMode === 'free') {
            JsonDataDetail.point = getters.getPoint
            commit('SET_POINT', JsonDataDetail.point)
          } else {
            commit('SET_SUBSCRIBE', JsonDataDetail.subscribe)
            commit('SET_POINT', JsonDataDetail.point)
          }
          
          // console.log(process.env.VUE_APP_KEY, process.env.VUE_APP_SECRET);
          resolve(data);
        }).catch(error => {
          reject(error)
        })
      })
    },
    getGames: ({commit}) => {
      accessApi().then(async (token) => {
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.get('games', { headers })
        const dataGames = JSON.parse(responseApi(data))
        commit('SET_LIST_GAME', dataGames)
      })
    },
    // getGamesFox: ({commit}) => {
    //   const dataGamesFox = [
    //     {
    //       gamefox_name: "Adrenaline BMX", 
    //       banner: require("@/assets/gamefox/gamefox1.png"),
    //       linkApp: "https://apaajadeh.com/games/AdrenalineBMX.apk",
    //     },
    //     {
    //       gamefox_name: "3D World Racing Challenge", 
    //       banner: require("@/assets/gamefox/gamefox2.png"),
    //       linkApp: "https://apaajadeh.com/games/3DWorldRacingChallenge.apk",
    //     },
    //     {
    //       gamefox_name: "Alien Parking", 
    //       banner: require("@/assets/gamefox/gamefox3.png"),
    //       linkApp: "https://apaajadeh.com/games/AlienParking.apk",
    //     },
    //     {
    //       gamefox_name: "Amazon River Challange", 
    //       banner: require("@/assets/gamefox/gamefox4.png"),
    //       linkApp: "https://apaajadeh.com/games/AmazonRiverChallenge.apk",
    //     },
    //     {
    //       gamefox_name: "Anthony The Mad Scientist", 
    //       banner: require("@/assets/gamefox/gamefox5.png"),
    //       linkApp: "https://apaajadeh.com/games/AnthonyTheMadScientist.apk",
    //     },
    //     {
    //       gamefox_name: "Addictive Truck Delivery", 
    //       banner: require("@/assets/gamefox/gamefox6.png"),
    //       linkApp: "https://apaajadeh.com/games/AddictiveTruckDelivery.apk",
    //     },
    //     {
    //       gamefox_name: "Alien Commando Strike", 
    //       banner: require("@/assets/gamefox/gamefox7.png"),
    //       linkApp: "https://apaajadeh.com/games/AlienCommandoStrike.apk",
    //     },
    //     {
    //       gamefox_name: "A Real Mole Adventure", 
    //       banner: require("@/assets/gamefox/gamefox8.png"),
    //       linkApp: "https://apaajadeh.com/games/arealmoleadventure.apk",
    //     }
    //   ]
    //   commit('SET_LIST_GAMEFOX', dataGamesFox)
    // },
    getLeaderboard: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const msisdn = toEncrypt(String(getters.getUser), getters.getSecret) 
        const sendData = { req: msisdn }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameLeaderboard', sendData, { headers })
        const dataLeaderboard = JSON.parse(responseApi(data))
        // console.log(dataLeaderboard);
        commit('SET_LEADERBOARD_LIST', dataLeaderboard)
      })
    },
    getInbox: ({commit}) => {
      accessApi().then( async (token) => {
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.get('playerInbox/list', {headers})
        commit('SET_INBOX_LIST', data.data)
      })
    },
    getBanner: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 4 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameSet', sendData, { headers })
        const dataGames = JSON.parse(responseApi(data))
        // console.log('data banner',dataGames);
        commit('SET_BANNER_LIST', dataGames)
      })
    },
    getRedeem: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 3 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameSet', sendData, { headers })
        const dataGames = JSON.parse(responseApi(data))
        // console.log(dataGames);
        commit('SET_REDEEM_LIST', dataGames)
      })
    },
    getStore: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 2 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameSet', sendData, { headers })
        const dataGames = JSON.parse(responseApi(data))
        // console.log(dataGames);
        commit('SET_STORE_LIST', dataGames)
      })
    },
    getReward: ({commit, getters}) => {
      accessApi().then(async (token) => {
        const type = toEncrypt(JSON.stringify({ type : 1 }), getters.getSecret)
        const sendData = { req: type }
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.post('gameSet', sendData, { headers })
        const dataGames = JSON.parse(responseApi(data))
        //  console.log(dataGames);
        commit('SET_REWARD_LIST', dataGames)
      })
    },
    getGameReward: ({commit}) => {
      accessApi().then(async (token) => {
        const headers = { Authorization : `bearer ${token}`}
        const { data } = await apiPortal.get('gameReward', { headers })
        const res = responseApi(data)
        const dataReward = JSON.parse(res)
        //  console.log(dataReward);
        commit('SET_REWARD', dataReward)
      })
    },
    getLife: ({commit, getters}) => {
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
          const unix = Math.round(+new Date()/1000);
          const unixSlice = unix.toString().slice(1, 10);
          const gameUnix = getters.getGameId+parseInt(unixSlice) 
          const datas = {
            game_id: gameUnix,
            msisdn: getters.getUser,
            type: "portal",
          }
          // console.log('data dikirim', datas);
          const request = toEncrypt(JSON.stringify(datas), getters.getSecret) 
          const sendData = { req: request }
          const headers = { Authorization : `bearer ${token}`}
          const { data } = await apiPortal.post('gameLife', sendData, { headers })
          const dataLife = await JSON.parse(responseApi(data))
          commit('SET_TOKEN_LIFE', dataLife.token_life)
          commit('SET_GAME_ID_UNIX', gameUnix)
          // console.log('getlife',dataLife);
          resolve(dataLife.token_life);
        }).catch(error => {
          reject(error)
        })
      })
    },
    getSubscriptionStatusByFakeId: ({commit,getters}) => {
      return new Promise((resolve, reject) => {
        const sendData = { fake_id: getters.getPid , app_id: 18}
        apiPortal.post('getSubscriptionStatusByFakeId', sendData).then(async (response) => {
          // console.log(response);
          const dataUser=response.data.data;
          commit('SET_USER', dataUser.msisdn)
          commit('SET_MNO_ID', dataUser.mno_id)
          commit('SET_TELCO', 'smart')
          // console.log('getlife',dataLife);
          resolve(response);
        }).catch(error => {
          reject(error)
        })
      })
        // const sendData = { fake_id: getters.getPid , app_id: 18}
        // apiPortal.post('getSubscriptionStatusByFakeId', sendData).then((response) => {
        //   const dataUser=response.data.data;
        //   commit('SET_USER', dataUser.msisdn)
        //   commit('SET_MNO_ID', dataUser.mno_id)
        //   commit('SET_TELCO', 'smart')
        // })
    },
    storePoint: async ({commit, getters}, point) => {
      return new Promise((resolve, reject) => {
        accessApi().then(async (token) => {
          const datas = {
            game_id: getters.getGameIdUnix,
            msisdn: getters.getUser,
            token_id: getters.getLifeId,
            data: point,
          }
          // console.log('store data', datas);
          const request = toEncrypt(JSON.stringify(datas), getters.getSecret)
          const sendData = { req: request }
          const headers = { Authorization : `bearer ${token}`}
          const { data } = await apiPortal.post('gamePoint', sendData, { headers })
          const dataPoint = await JSON.parse(responseApi(data))
          // console.log('response store', dataPoint)
          commit('SET_DATA_POINT', dataPoint)
          resolve(dataPoint)
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  modules: {
    // login
  }
});
