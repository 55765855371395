<template>
  <div id="app">
    <div v-if="isMobile">
      <div v-if="isLoading">
        <Loader :isLoading="isLoading" />
      </div>
        <transition :name="transitionName">
        <router-view />
      </transition>
      </div>
    <div v-else>
      <div class="img-background">
        <img src="@/assets/material cuantastic_home.png" alt="" class="w-25">
        <div class="card-bgDesktop">
          <h2 class="title-bgDesktop">Mulai Main di Hapemu!</h2>
          <p class="parag-bgDesktop">Hi! untuk mulai bermain, buka halaman ini di handphonemu ya :D</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Loader from '@/components/Loader.vue'
import { mapState } from 'vuex';
export default {
  components: {
    Loader,
  },
  data() {
    return {
      isMobile: false,
      background: require("@/assets/play-phone.jpg"),
      transitionName: "",
      // isLoading: true,
    };
  },
  computed: {
    ...mapState([
      'isLoading'
    ])
  },
  watch: {
    $route(to, from) {
      this.transitionName = to.meta.page > from.meta.page ? "next" : "prev";
      // console.log(this.transitionName);
    }
  },
  beforeMount(){
    this.$store.state.isLoading = false
  },
  mounted() {
    this.$nextTick(function() {
      window.addEventListener("resize", this.handleResize);
      this.handleResize();
    });
  },
  methods: {
    handleResize() {
      if (this.$isMobile()) {
        this.isMobile = true;
      } else {
        this.isMobile = false;
      }
    }
  },
  beforeDestroy() {
    window.addEventListener("resize", this.handleResize);
  }
};
</script>

<style>
html,
body {
  /* height: 100%; */
  min-height: 100vh;
}
.img-background {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgb(241, 239, 239);
  background-image: url("./assets/bgrd_LandingPage.png");
  background-position: center;
  background-size: cover;
  min-height: 100vh;
}
.card-bgDesktop {
  background: #14161785;
  width: 30%;
  border-radius: 16px;
  margin-bottom: 4vh;
}
.title-bgDesktop {
  font-weight: bold;
  padding: 16px 0;
  padding-bottom: 2px;
  color: #fab92b;
  font-size: 20px;
}
.parag-bgDesktop {
  padding: 0 8px;
  color: #AEAEAE;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

.b-sidebar {
  /* background: linear-gradient(90deg, #ff4401 0%, #ff0077 57.29%) !important; */
  background: linear-gradient(#120D50 0%, #0f0b44 57.29%) !important;
  /* background-color: #D82827 !important; */
}

.btn-primary {
  color: #222 !important;
  background-color: #fff !important;
  border-color: #fff !important;
}

.btn-success {
  color: #fff !important;
  background-color: #14142b !important;
  border-color: #14142b !important;
}

.btn-warning {
  color: #000 !important;
  /* background: linear-gradient(90deg, #D82827 0%, #D82827 57.29%) !important; */
  background: #FECF2F;
  border-color: transparent !important;
}

.pt-6 {
  padding-top: 6rem !important;
}

main {
  min-height: 100%;
  display: grid;
  grid-template: "main";
  flex: 1;
  background-color: white;
  position: relative;
  z-index: 0;
  overflow-x: hidden;
}

main > * {
  grid-area: main; /* Transition: make sections overlap on same cell */
  background-color: white;
  position: relative;
}

main > :first-child {
  z-index: 1; /* Prevent flickering on first frame when transition classes not added yet */
}

/* Transitions */

.next-leave-to {
  animation: leaveToLeft 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 0;
}

.next-enter-to {
  animation: enterFromRight 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 1;
  top: 0px;
}

.prev-leave-to {
  animation: leaveToRight 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 1;
  top: 0px;
}

.prev-enter-to {
  animation: enterFromLeft 500ms both cubic-bezier(0.165, 0.84, 0.44, 1);
  z-index: 0;
}

@keyframes leaveToLeft {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
    filter: brightness(0.5);
  }
}

@keyframes enterFromLeft {
  from {
    transform: translateX(-100%);
    filter: brightness(0.5);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes leaveToRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes enterFromRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}
</style>
